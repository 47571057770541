import React from "react";
import 'animate.css';
import slider_01 from "../Assets/images/gallery-slider-01.jpg";
import slider_02 from "../Assets/images/gallery-slider-02.jpg";
import slider_03 from "../Assets/images/gallery-slider-03.jpg";
import slider_04 from "../Assets/images/gallery-slider-04.jpg";
import slider_05 from "../Assets/images/gallery-slider-05.jpg";
const GallerySlider = () => {

    return (
        <>
            <div id="carouselExampleIndicators" className="carousel slide block-slider" data-bs-ride="true">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active"
                        aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"
                        aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4"
                        aria-label="Slide 5"></button>
                </div>
                <div className="carousel-inner block-slider-inner">
                    <div className="carousel-item active animate__animated animate__bounceInRight">
                        <img src={slider_01} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item animate__animated animate__bounceInRight">
                        <img src={slider_02} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item animate__animated animate__bounceInRight">
                        <img src={slider_03} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item animate__animated animate__bounceInRight">
                        <img src={slider_04} className="d-block w-100" alt="..." />
                    </div>
                    <div className="carousel-item animate__animated animate__bounceInRight">
                        <img src={slider_05} className="d-block w-100" alt="..." />
                    </div>
                </div>
            </div>
        </>
    )

}

export default GallerySlider;
import React from "react";
import Nav from '../Layout/Nav';

const About = () => {
return(
<>
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-6  block-image'>
                <Nav />
            </div>
            <div className='col-lg-6 block-left'>
                <div className='row justify-content-center'>
                    <div className='col-lg-10 block-container-About'>
                        <h1 className='animate__animated animate__backInDown'>What <br /> <span
                                className='block-light-h1'>We Do</span></h1>
                        <p className='animate__animated animate__backInLeft'>Lorem Ipsum is simply dummy text of the
                            printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.</p>
                        <p className='animate__animated animate__backInLeft'>Lorem Ipsum is simply dummy text of the
                            printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
)
}
export default About;
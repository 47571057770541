import React from 'react';
import 'animate.css';

import Nav from './Nav';
import Slider from './Slider';

const Layout = () => {
return (
<>
    <div className='container-fluid'>
        <div className='row'>
        <div className='col-lg-6 block-right'>
                <Nav />
                <Slider />
            </div>
            <div className='col-lg-6 block-left'>
                <div className='row justify-content-center'>
                    <div className='col-lg-10 block-container-logo'>
                        <h1 className='animate__animated animate__backInDown'>Creative<br /> <span className='block-light-h1'>Digitals</span></h1>
                        <p className='animate__animated animate__backInLeft'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                            galley of type and scrambled it to make a type specimen book.</p>
                        <ul className="nav col-md-10 justify-content-start list-unstyled d-flex animate__animated animate__backInLeft">
                            <li>Instagram </li>
                            <li className="ms-3">Facebook</li>
                            <li className="ms-3">twitter</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
)
}

export default Layout;
import React from 'react';
import Layout from '../Layout/Layout';

const Home = () => {
    return (
        <>
            <Layout />
        </>

    )
}

export default Home;
import React from "react";
import 'animate.css';
import slider_01 from "../Assets/images/slider-01.jpg";
import slider_02 from "../Assets/images/slider-02.jpg";
import slider_03 from "../Assets/images/slider-03.jpg";
const Slider = () => {

return(
<>
    <div id="carouselExampleIndicators" className="carousel slide block-slider" data-bs-ride="true">
        <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active"
                aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner block-slider-inner">
            <div className="carousel-item active animate__animated animate__bounceInRight">
                <img src={slider_01} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item animate__animated animate__bounceInRight">
                <img src={slider_02} className="d-block w-100" alt="..." />
            </div>
            <div className="carousel-item animate__animated animate__bounceInRight">
                <img src={slider_03} className="d-block w-100" alt="..." />
            </div>
        </div>
    </div>
</>
)

}

export default Slider;
import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Gallery from './Pages/Gallery';
import Contact from './Pages/Contact';

function App() {
return (
<>
  <BrowserRouter>
    <Routes>
      <Route index element={<Home />}></Route>
      <Route path='about' element={<About />}></Route>
      <Route path='gallery' element={<Gallery />}></Route>
      <Route path='contact' element={<Contact />}></Route>
    </Routes>
  </BrowserRouter>
</>

);
}
export default App;
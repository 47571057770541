import React from "react";
import Nav from '../Layout/Nav';

const Contact = () => {
return (
<>
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-6  block-image-contact'>
                <Nav />
            </div>
            <div className='col-lg-6 block-left'>
                <div className='row justify-content-center'>
                    <div className='col-lg-10 block-container-About'>
                        <h1 className='animate__animated animate__backInDown'>Get in <br /> <span
                                className='block-light-h1'>Touch</span></h1>
                        <form>
                            <div class="mb-3">
                                <label for="exampleInputEmail1" class="form-label">Full Name</label>
                                <input type="text" className="form-control" id="exampleInputEmail1"
                                    aria-describedby="emailHelp" />
                            </div>
                            <div class="mb-3">
                                <label for="exampleInputPassword1" class="form-label">Email</label>
                                <input type="text" className="form-control" id="email" />
                            </div>
                            <div class="mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Whats on your mind</label>
                                <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                            <button type="submit" className="btn btn-primary">Contact Me</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
)
}

export default Contact;